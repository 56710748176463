<template>
  <v-container fluid class="pa-sm-5 px-1 mobile-height">
    <skeleton v-show="$apollo.queries.user.loading" />
    <profile-component :user="user" v-if="user && !$apollo.queries.user.loading" :isMe="isCurrentUser" :registerStatus="registerStatus" :currentUser="currentUser"/>
    <v-card v-if="user && !isGuest">
      <v-tabs v-model="tab">
        <v-tab :to="{name: 'profile', params: {id: id}}">
          <v-badge
            inline
            bordered
            color="primary"
            :content="user.counts.posts"
            v-show="user.counts.posts"
          >
            Posts
          </v-badge>
          <span v-show="!user.counts.posts">
            Posts
          </span>
        </v-tab>
      </v-tabs>
      <posts-tab :id="this.id" />
    </v-card>
    <div v-if="isGuest && !$apollo.queries.user.loading" class="mt-5">
      <v-btn color="secondary" depressed x-large block :to="{name: 'login'}" class="mb-0">
        <v-icon small class="mr-2">mdi-lock</v-icon>
        Please Login to view full Profile
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import ProfileComponent from '../components/ProfileComponent'
import PostsTab from '../components/ProfileComponent/PostsTab'
import Skeleton from '../components/Utils/Skeleton'
import USER from '../graphql/queries/user.graphql'
export default {
  metaInfo () {
    return {
      title: this.user && `${this.user.meta.first_name} ${this.user.meta.last_name}'s`,
      titleTemplate: '%s Profile | Gradbee',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.user && `${this.user.meta.first_name} ${this.user.meta.last_name} is a student of ${this.user.profile.college[0].name} and is located at ${this.user.meta.location} and has the following skills - ${this.keywords}`
      }, {
        vmid: 'keywords',
        name: 'keywords',
        content: this.keywords
      }]
    }
  },
  apollo: {
    user: {
      query: USER,
      variables () {
        return {
          id: this.id
        }
      }
    }
  },
  props: {
    id: {
      default: null
    },
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tab: 0,
      page: 1,
      allFetched: false
    }
  },
  computed: {
    isCurrentUser () {
      if (this.currentUser.ID === this.id) {
        this.redirect()
      }
      return false
    },
    registerStatus () {
      return this.currentUser && this.currentUser.meta && this.currentUser.meta.register_status === 'confirm'
    },
    isGuest () {
      return Object.keys(this.currentUser).length === 0 && this.currentUser.constructor === Object
    },
    keywords () {
      return this.user && this.user.profile.skill.map(item => item.name).toString()
    }
  },
  methods: {
    showMore () {
      this.page++
      // Fetch more data and transform the original result
      this.$apollo.queries.projects.fetchMore({
        // New variables
        variables: {
          paged: this.page
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            projects: {
              __typename: previousResult.projects.__typename,
              // Merging the list
              list: [...previousResult.projects.list, ...fetchMoreResult.projects.list]
            }
            // meta: {
            //   __typename: previousResult.meta.__typename,
            //   // Merging the tag list
            //   list: [...fetchMoreResult.meta.list]
            // }
          }
        }
      }).then(res => {
        this.allFetched = res.data.projects.list.length === 0
      })
    },
    redirect () {
      this.$router.replace({ name: 'myProfile' })
    }
  },
  components: {
    ProfileComponent,
    PostsTab,
    Skeleton
  }
}
</script>

<style scoped>

</style>
